<template>
  <v-card class="fill-height" @click="e => $emit('click', e)">
    <div class="d-flex flex-column justify-start" style="position: absolute; z-index: 1;">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip :class="item.ActiveServiceJobs ? 'alert active' : 'alert'" class="mx-1 mt-1 px-2" small
            color="#8a55f6" dark v-bind="attrs" v-on="on">
            {{ item.ActiveServiceJobs }}
            <v-icon size="14" class="ml-1">mdi-alert</v-icon>
            <v-icon size="14">mdi-briefcase</v-icon>
          </v-chip>
        </template>
        <span>{{ item.ActiveServiceJobs }} open service orders</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip :class="item.ActivePartsJobs ? 'alert active' : 'alert'" class="mx-1 mt-1 px-2" small color="#e955f6"
            dark v-bind="attrs" v-on="on">
            {{ item.ActivePartsJobs }}
            <v-icon size="14" class="ml-1">mdi-alert</v-icon>
            <v-icon size="14">mdi-tag</v-icon>
          </v-chip>
        </template>
        <span>{{ item.ActivePartsJobs }} open part orders</span>
      </v-tooltip>
    </div>

    <v-row no-gutters justify="center" class="py-2">
      <MachineModelImage :size="100" :relativePath="item && item.MachineModel && item.MachineModel.ModelImage">
      </MachineModelImage>
    </v-row>

    <MachineListItem :item="item" hideImage>
    </MachineListItem>

    <v-divider class="mt-2" />

    <v-row no-gutters class="py-2" style="background-color: #f5f5f5;">
      <v-col>
        <v-card class="pl-4 pr-2 mb-1" style="background-color: #f5f5f5;" tile elevation="0">
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <small>Cost type</small>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pr-1">
              <small>Lifetime cost</small>
            </v-col>
            <v-col cols="2">

            </v-col>
          </v-row>
        </v-card>

        <v-btn class="pl-4 pr-2" style="width: 100%; text-transform: none; height: 32px;" tile elevation="0"
          :to="{ name: 'ServicePanel', params: { id: item.Id } }">
          <v-row no-gutters align="center" style="font-size: 13px;">
            <v-col cols="auto">{{ $t("text.Service") }}</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-end">{{ formatCurrencyTotal(item.TotalServiceAmount) }}</v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn icon small class="ml-1">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-btn>

        <v-btn class="pl-4 pr-2" style="width: 100%; text-transform: none; height: 32px;" tile elevation="0"
          :to="{ name: 'PartsPanel', params: { id: item.Id } }">
          <v-row no-gutters align="center" style="font-size: 13px;">
            <v-col cols="auto">{{ $t("text.Parts") }}</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-end">{{ formatCurrencyTotal(item.TotalPartsAmount) }}</v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn icon small class="ml-1" :style="item.MachineModelId ? '' : 'opacity: 0;'">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";

export default {
  name: "MachineOverviewCard",
  props: {
    item: Object,
  },
  components: {
    MachineListItem,
    MachineModelImage,
  },

  mounted() {
    this.$emit('mount');
  },

  methods: {},

  computed: {},

  watch: {}
};
</script>

<style>
.alert {
  transition: opacity 1s ease-out;
  opacity: 0;
  border-radius: 4px !important;
  width: fit-content;
}

.alert.active {
  opacity: 1;
}
</style>
<template>
    <v-dialog v-model="dialog" width="500" :persistent="loading">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="userInfo.UserTypeId != 6" v-bind="attrs" v-on="on" small color="success" class="px-1"
                style="min-width: unset;">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="py-2">
                <h3>New user</h3>
            </v-card-title>
            <v-card-text v-if="newuser" class="pt-2 mb-3">
                <v-alert v-if="error" type="error" style="max-height: 150px;">
                    Falied to create user. {{ error }}.
                </v-alert>
                <v-form v-model="valid">
                    <v-text-field v-model="newuser.Name" label="Name" :rules="rules.required">
                    </v-text-field>
                    <v-text-field v-model="newuser.EmailAdress" label="Email" :rules="rules.email">
                    </v-text-field>
                    <v-row no-gutters>
                        <small style="font-weight: normal;">Type</small>
                    </v-row>
                    <v-btn-toggle v-model="newuser.UserTypeId" mandatory dense>
                        <v-btn :value="4" :color="newuser.UserTypeId == 4 ? '#08a7a0' : 'grey'" text
                            style="text-transform: none;">
                            Moderator
                        </v-btn>
                        <v-btn :value="6" :color="newuser.UserTypeId == 6 ? '#018500' : 'grey'" text
                            style="text-transform: none;">
                            User
                        </v-btn>
                    </v-btn-toggle>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="!loading" outlined color="error" text @click="close()">
                    {{ $t('text.close') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" color="primary" :disabled="!valid" @click="save()">
                    {{ $t('text.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import http from "@/api/client";

export default {
    name: 'NewUserDialog',
    props: {},
    components: {},

    data() {
        return {
            dialog: false,
            loading: false,
            valid: false,
            error: null,
            newuser: null,
            userTypes: [
                { Id: 4, Name: 'Moderator' },
                { Id: 6, Name: 'User' },
            ],
            rules: {
                required: [val => (val || '').length > 0 || 'This field is required'],
                email: [
                    (v) => !!v || "E-mail is required",
                    (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "E-mail must be valid",
                ],
            },
        };
    },

    created() {
        console.log('userInfo', this.userInfo);
    },

    methods: {
        close() {
            this.dialog = false;
        },

        async save() {
            this.error = null;
            this.loading = true;
            await http()
                .post("api2/customer/User/add", this.newuser)
                .then(() => {
                    this.close();
                    this.$emit('refresh');
                })
                .catch((error) => {
                    console.error(error);
                    this.error = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                this.error = null;
                this.newuser = {
                    Name: '',
                    EmailAdress: '',
                    UserTypeId: 4,
                    CustomerId: this.userInfo.Customer.Id,
                    Active: true,
                };
            }
        }
    },
};
</script>


<style lang='scss' scoped></style>